// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "/* purgecss start ignore */\n\n.login-Container-3wtGx{\n  padding: 24px;\n}\n\n/* purgecss end ignore */", "",{"version":3,"sources":["<no source>","styles.module.css"],"names":[],"mappings":"AAAA,2BAAA;;ACAA;EACI,aAAa;AACjB;;ADFA,yBAAA","file":"styles.module.css","sourcesContent":[null,".Container{\r\n    padding: 24px;\r\n}"]}]);
// Exports
exports.locals = {
	"Container": "login-Container-3wtGx"
};
module.exports = exports;
