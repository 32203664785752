import React,{useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom'
import './App.css';
import './i18n'
import Authentication from './module'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import i18next from 'i18next';

function App(props) {
  useEffect(() => {
    console.log(i18next.language)
    localStorage.setItem('Defaultlanguage',i18next.language)
    let currentLang = localStorage.getItem("currentlanguage");
    console.log("231312",currentLang)
    i18next.changeLanguage(currentLang, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    });
  });
  return (
    <BrowserRouter basename="/login">
        <GoogleReCaptchaProvider reCaptchaKey="6LdAobgZAAAAAHOv_DcJ0UQ1MY-TOehyQOKg4LUJ">
          <Authentication {...props}/>
        </GoogleReCaptchaProvider>
    </BrowserRouter>
  );
}

export default App;
