// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "/* purgecss start ignore */\n\n.login-forgot_container-21KZJ{\n  display: flex;\n  position: relative;\n  justify-content: flex-end;\n  top: -0.5em;\n  margin-bottom: 1em;\n}\n\n.login-forget_anchor-1lsj_{\n  text-decoration: none;\n  color: #2a8ccf;\n  font-size: 14px;\n}\n\n/* purgecss end ignore */", "",{"version":3,"sources":["<no source>","styles.module.css"],"names":[],"mappings":"AAAA,2BAAA;;ACAA;EACI,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACtB;;AACA;EACI,qBAAqB;EACrB,cAAc;EACd,eAAe;AACnB;;ADXA,yBAAA","file":"styles.module.css","sourcesContent":[null,".forgot_container{\r\n    display: flex;\r\n    position: relative;\r\n    justify-content: flex-end;\r\n    top: -0.5em;\r\n    margin-bottom: 1em;\r\n}\r\n.forget_anchor{\r\n    text-decoration: none;\r\n    color: #2a8ccf;\r\n    font-size: 14px;\r\n}"]}]);
// Exports
exports.locals = {
	"forgot_container": "login-forgot_container-21KZJ",
	"forget_anchor": "login-forget_anchor-1lsj_"
};
module.exports = exports;
