import React from 'react'
import axios from 'axios'
const qs = require('querystring')
var jwt = require('jsonwebtoken');

class Callback extends React.Component{

    async componentDidMount(){
        try{
            document.getElementById("login").style.display="none"
            let url_string = window.location.href;
            let url = new URL(url_string);
            let state = url.searchParams.get("state");
            if(state === localStorage.getItem("state")){
              let domain = localStorage.getItem("domain")
              let response = await this.props.getOrgPool(domain)
              response["clientIdInUse"] = response.verisClient
              let code = url.searchParams.get("code");
              const requestBody = {
                  grant_type: 'authorization_code',
                  code: code,
                  client_id: response.verisClient,
                  redirect_uri: `${window.location.origin}/login/callback/`,
                  code_verifier: localStorage.getItem("code_verifier")
                }
              let config = {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }
              let authenticationResult = await axios.post(`${response.cognitoDomain.default.url}/oauth2/token`, qs.stringify(requestBody), config)
              authenticationResult = authenticationResult.data
              response["AuthenticationResult"] = {}
              response["AuthenticationResult"]["IdToken"] = authenticationResult.id_token
              response["AuthenticationResult"]["AccessToken"] = authenticationResult.access_token
              response["AuthenticationResult"]["RefreshToken"] = authenticationResult.refresh_token
              response["AuthenticationResult"]["ExpiresIn"] = authenticationResult.expires_in

              let idToken = jwt.decode(authenticationResult.id_token)
              config = {
                headers: {
                  'Authorization': `bearer ${authenticationResult.access_token}`,
                  'Pool': `pool_${response.poolId}`
                }
              }
              let verisToken = await axios.get(`/api/v4/get-secured-token/?domain=${response.domain}&contact=${idToken.email}`, config)
              response["token"]= verisToken.data.token
              response["member"] = verisToken.data.member
              this.props.signIn(response)
            }
            else{
              window.location.href="/"
            }
        }
        catch(error){
            // console.log(error)
            document.getElementById("login").style.display="block"
            window.location.href="/"
        }
    }
    render(){
        return <div/>
    }
}


export default Callback