import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import singleSpaReact from 'single-spa-react';
import 'antd/dist/antd.css'

import * as Sentry from "@sentry/browser";

// [Sentry]
Sentry.init({
    dsn: "https://0b8b8e903c054fb9a60948de7b2ef25b@sentry2.veris.in/27",
});

const rootComponent = App
//
// const reactLifecycles = singleSpaReact({
//     React,
//     ReactDOM,
//     rootComponent,
//     domElementGetter: () => document.getElementById('login')
// });
//
// export const bootstrap = [
//     reactLifecycles.bootstrap,
// ];
//
// export const mount = [
//     reactLifecycles.mount,
// ];
//
// export const unmount = [
//     reactLifecycles.unmount,
// ];


// require('veris-styleguide/dist/index.css')
ReactDOM.render(<App />, document.getElementById('login'));

if (process.env.NODE_ENV === 'development') {
    // require('veris-styleguide/dist/index.css')
    ReactDOM.render(<App />, document.getElementById('login'));
    serviceWorker.unregister();
}
