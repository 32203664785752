import axios from 'axios'

class Singleton {

    static instance
    static accessToken
    static idToken
    static poolId
    static orgId
    static config = {
        idToken: "778cc680cd0286e3e5d582e3febd65f5c9cccbc5", //Set id token here for development environment
        accessToken: "eyJraWQiOiJOUTRcLzI3Nk9RMEpLcmR1bjlMT3JEUEJPbTU1VElTV2YwK1A1VHFQS1lJdz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwOTUyY2NhOS1iMmNmLTQ5NGEtOTYxNS00NzgwOWM3Y2JmMzAiLCJldmVudF9pZCI6Ijc1ZjIwNGJmLTVkNjUtNDhmZi1hYWZlLTY1YTE4NDM4MmU5NSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MTEzMDQ4MzUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yX1k5UlpmYzhjciIsImV4cCI6MTYxMTMxNzI1NywiaWF0IjoxNjExMzEzNjU3LCJqdGkiOiJjYmE4YmQzNC1iYWYxLTRhZjQtOGFmNS0xYjZjZGE5OGUxNTciLCJjbGllbnRfaWQiOiI3aG82MGJpdGkxOGRrZjRidWQ4cGN0a2Y5YyIsInVzZXJuYW1lIjoic2hhaWxlbmRyYS10aXJhbmdhLXZlcmlzLWluIn0.bs4_sZf-fSk8bTPrFoiTfKZqyeB_z0y1lVeo1OKUUOg1VXJQsLQgv15LgRxSD_NpMBfm71xPoF7rbphA8oxLHGYFiygqQu-X7quirBSUvYUByyMj_wFkVWtBZZCFdQ9yr3Ds8JpjZuqZ1_O9mB0ArXwlwV9r1Q2vAwQjZeSKHzFBPr9vLRw3kmFO-aPOCBlEI5_tfWM7anLqULw1--_WzDV_9w9ulPAFzTZq8oFlpftzocaPHvhBE3MJddfUuaqXEq5mUgGPSMxPY3bK2c8wQsRS04gPNvWWql2JbDXtdl6uZ4lw7Icum88QG_H3glsmgnlyrCP42DmOZXi3b-0nYg",
        poolId: 30, //Set pool id here for development environment
        orgId: 56, //Set orgId here for development environment
        roles: ["can enter", "Venue Admin", "can invite", "Organisation Admin"],
    }
    static getAccessToken = (token) => {
        Singleton.token = token
    }
    static getAccessToken = (token) => {
        return Singleton.token
    }

    static setOrgId = (orgId) => {
        Singleton.orgId = orgId
    }
    static getOrgId = () => {
        return Singleton.orgId
    }

    static setPoolId = (id) => {
        Singleton.poolId = id
    }
    static getPoolId = () => {
        return Singleton.poolId
    }


    static setInstance = (instance) => {
        Singleton.instance = instance
    }
    static getInstance = () => {
        if (Singleton.instance) {
            return Singleton.instance
        }
        else {
            Singleton.instance = axios.create();
            Singleton.instance.interceptors.request.use((config) => {
                return config;
            }, (error) => {
                return Promise.reject(error);
            });

            Singleton.instance.interceptors.response.use(response => {
                return response
            }, (error) => {
                throw error
            })
        }
        return Singleton.instance
    }
}


export default Singleton