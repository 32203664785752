// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "/* purgecss start ignore */\n\n.login-LabelContainer-vRLOx{\n  display: flex;\n  justify-content: space-between;\n  align-items: center\n}\n\n.login-PasswordLink-8O_eE{\n  padding: 0px;\n  margin: 0px;\n  font-size: 14px;\n  line-height: 22px;\n  color: #6e7380;\n  margin-bottom: 3px;\n  cursor: pointer;\n}\n\n.login-PasswordLink-8O_eE:hover{\n  color: #242934;\n}\n\n/* purgecss end ignore */\r\n", "",{"version":3,"sources":["<no source>","styles.module.css"],"names":[],"mappings":"AAAA,2BAAA;;ACAA;EACI,aAAa;EACb,8BAA8B;EAC9B;AACJ;;AACA;EACI,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,eAAe;AACnB;;AACA;EACI,cAAc;AAClB;;ADhBA,yBAAA","file":"styles.module.css","sourcesContent":[null,".LabelContainer{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center\r\n}\r\n.PasswordLink{\r\n    padding: 0px;\r\n    margin: 0px;\r\n    font-size: 14px;\r\n    line-height: 22px;\r\n    color: #6e7380;\r\n    margin-bottom: 3px;\r\n    cursor: pointer;\r\n}\r\n.PasswordLink:hover{\r\n    color: #242934;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"LabelContainer": "login-LabelContainer-vRLOx",
	"PasswordLink": "login-PasswordLink-8O_eE"
};
module.exports = exports;
