export const translations =
    {
        "en": {
            "app.i-help": "Need help? Contact Veris Support at helpdesk@veris.in",
            "app.err-required": "This field is required.",
            "app.err-contact-0": "Enter valid email or phone number with country code",
            "app.err-email-0": "Enter valid email",
            "app.err-setPasssword-0": "Password should have at least one lower case character",
            "app.err-setPasssword-1": "Password should have at least one upper case character",
            "app.err-setPasssword-2": "Password should have at least one number",
            "app.err-setPasssword-3": "Password should have at least one special character, allowed characters ! @ # $ & *",
            "app.err-setPasssword-4": "Allowed special characters ! @ # $ & *",
            "app.err-setPasssword-5": "Password should be at least 10 characters",
            "app.err-confirmPasssword-0": "Passwords don't match.",
            "app.err-otp-0": "Enter valid otp",
            "domain.h-1": "Organization Domain",
            "domain.sh-1": "Enter your Veris organisation domain to start",
            "domain.f-domain-label": "Organization Domain",
            "domain.f-domain-placeholder": "Enter Domain",
            "domain.f-domain-suffix": ".veris.in",
            "domain.lk-forgot-domain": "Forgot Domain?",
            "domain.btn-continue": "Continue",
            "domain.err-0": "Domain not found!",
            "forgotDomain.h-1": "Forgot Domain",
            "forgotDomain.sh-1": "Please enter your email to receive your list of domains",
            "forgotDomain.f-email-label": "Admin E-Mail ID",
            "forgotDomain.f-email-placeholder": "Enter admin e-mail id",
            "forgotDomain.btn-send": "Send on E-Mail",
            "forgotDomain.err-0": "Something went wrong!",
            "signInPassword.h-1": "Sign in to",
            "signInPassword.f-contact-label": "Your email/phone *",
            "signInPassword.f-contact-placeholder": "Enter your email or phone",
            "signInPassword.lk-otp": "Sign in with OTP?",
            "signInPassword.f-password-label": "Your password",
            "signInPassword.f-password-placeholder": "Enter password",
            "signInPassword.btn-continue": "Continue",
            "signInPassword.lk-forgot": "Forgot Password?",
            "signInPassword.btn-okta": "Login with Okta",
            "signInPassword.btn-google": "Login with Google",
            "signInPassword.err-0": "Incorrect credentials!",
            "signInPassword.err429": "User blocked! try after a minute",
            "signInPassword.err429_plural": "User blocked! try after {{count}} minutes",
            "forgotPassword.h-1": "Forgot Password",
            "forgotPassword.f-contact-label": "Your email/phone *",
            "forgotPassword.f-contact-placeholder": "Enter your email or phone",
            "forgotPassword.lk-otp": "Sign in with otp?",
            "forgotPassword.btn-verification-link": "Request Verification Link",
            "forgotPassword.i-footer": "We will send you verification link on your provided contact.",
            "forgotPassword.err-0": "Something went wrong!",
            "signInOtpContact.h-1": "Sign in to",
            "signInOtpContact.f-contact-label": "Your email/phone *",
            "signInOtpContact.f-contact-placeholder": "Enter your email or phone",
            "signInOtpContact.lk-password": "Sign in with password?",
            "signInOtpContact.btn-continue": "Continue",
            "signInOtpContact.i-footer": "We will send you an OTP on your provided contact.",
            "signInOtpContact.err-0": "Member does not exist!",
            "signInOtpContact.err429": "User blocked! try after a minute",
            "signInOtpContact.err429_plural": "User blocked! try after {{count}} minutes",
            "signInOtpCode.h-1": "Sign in to",
            "signInOtpCode.f-otp-label": "Enter OTP *",
            "signInOtpCode.lk-password": "Sign in with password?",
            "signInOtpCode.btn-continue": "Continue",
            "signInOtpCode.btn-resend": "Resend OTP",
            "signInOtpCode.i-resend": "Resend OTP in",
            "signInOtpCode.succ-0": "OTP resent!",
            "signInOtpCode.err-0": "Something went wrong!",
            "signInOtpCode.err-1": "Wrong OTP",
            "signInOtpCode.err-2": "Too many failed attempts!",
            "signInOtpCode.err429": "User blocked! try after a minute",
            "signInOtpCode.err429_plural": "User blocked! try after {{count}} minutes",
            "box.header-back_btn": "Go Back",
            "Languages": "Languages",
            "enterEmail": "Your Email",
            "enterPhone": "Your Phone",
            "app.err-phone-0": "Enter valid phone",
            "enterEmail-placeholder": 'Please enter your email',
            "enterPhone-placeholder": 'Please enter your phone'
        },
        "hi": {
            "app.i-help": "मदद की ज़रूरत है? helpdesk@veris.in पर संपर्क करें",
            "app.err-required": "यह फ़ील्ड आवश्यक है",
            "app.err-contact-0": "देश कोड के साथ मान्य ईमेल या फ़ोन नंबर दर्ज करें",
            "app.err-email-0": "मान्य ईमेल दर्ज करें",
            "app.err-setPasssword-0": "पासवर्ड में कम से कम एक कम वर्ण होना चाहिए",
            "app.err-setPasssword-1": "पासवर्ड में कम से कम एक ऊपरी मामला होना चाहिए",
            "app.err-setPasssword-2": "पासवर्ड में कम से कम एक नंबर होना चाहिए",
            "app.err-setPasssword-3": "पासवर्ड में कम से कम एक विशेष वर्ण, अनुमत वर्ण होना चाहिए! @ # $ & *",
            "app.err-setPasssword-4": "विशेष चरित्रों की अनुमति है! @ # $ & *",
            "app.err-setPasssword-5": "पासवर्ड कम से कम 10 अक्षर का होना चाहिए",
            "app.err-confirmPasssword-0": "पासवर्ड मेल नहीं खाते।",
            "app.err-otp-0": "मान्य otp दर्ज करें",
            "domain.h-1": "संगठन डोमेन",
            "domain.sh-1": "शुरू करने के लिए अपना वेरिस संगठन डोमेन दर्ज करें",
            "domain.f-domain-label": "संगठन डोमेन",
            "domain.f-domain-placeholder": "डोमेन दर्ज करें",
            "domain.f-domain-suffix": ".veris.in",
            "domain.lk-forgot-domain": "डोमेन भूल गए?",
            "domain.btn-continue": "जारी रखें",
            "domain.err-0": "डोमेन नहीं मिला!",
            "forgotDomain.h-1": "डोमेन भूल गए",
            "forgotDomain.sh-1": "डोमेन की अपनी सूची प्राप्त करने के लिए कृपया अपना ईमेल दर्ज करें",
            "forgotDomain.f-email-label": "व्यवस्थापक ई-मेल आईडी",
            "forgotDomain.f-email-placeholder": "ई-मेल आईडी दर्ज करें",
            "forgotDomain.btn-send": "ई-मेल पर भेजें",
            "forgotDomain.err-0": "कुछ गलत हो गया!",
            "signInPassword.h-1": "साइन इन करें",
            "signInPassword.f-contact-label": "आपका ईमेल / फोन *",
            "signInPassword.f-contact-placeholder": "अपना ईमेल या फ़ोन दर्ज करें",
            "signInPassword.lk-otp": "OTP के साथ साइन इन करें",
            "signInPassword.f-password-label": "आपका पासवर्ड",
            "signInPassword.f-password-placeholder": "पास वर्ड दर्ज करें",
            "signInPassword.btn-continue": "जारी रखें",
            "signInPassword.lk-forgot": "पासवर्ड भूल गए?",
            "signInPassword.btn-okta": "ओक्टा के साथ लॉगिन करें",
            "signInPassword.btn-google": "Google के साथ लॉगिन करें",
            "signInPassword.err-0": "गलत परिचय!",
            "signInPassword.err429": "उपयोगकर्ता अवरुद्ध! एक मिनट के बाद प्रयास करें",
            "signInPassword.err429_plural": "उपयोगकर्ता अवरुद्ध! {{count}} मिनट के बाद प्रयास करें",
            "forgotPassword.h-1": "पासवर्ड भूल गए",
            "forgotPassword.f-contact-label": "आपका ईमेल / फोन *",
            "forgotPassword.f-contact-placeholder": "अपना ईमेल या फ़ोन दर्ज करें",
            "forgotPassword.lk-otp": "ओटीपी के साथ साइन इन करें",
            "forgotPassword.btn-verification-link": "सत्यापन लिंक का अनुरोध करें",
            "forgotPassword.i-footer": "हम आपको आपके प्रदान किए गए संपर्क पर सत्यापन लिंक भेज देंगे।",
            "forgotPassword.err-0": "कुछ गलत हो गया!",
            "signInOtpContact.h-1": "साइन इन करें",
            "signInOtpContact.f-contact-label": "आपका ईमेल / फोन *",
            "signInOtpContact.f-contact-placeholder": "अपना ईमेल या फ़ोन दर्ज करें",
            "signInOtpContact.lk-password": "पासवर्ड के साथ साइन इन करें",
            "signInOtpContact.btn-continue": "जारी रखें",
            "signInOtpContact.i-footer": "हम आपको आपके प्रदान किए गए संपर्क पर एक ओटीपी भेजेंगे।",
            "signInOtpContact.err-0": "सदस्य मौजूद नहीं है!",
            "signInOtpContact.err429": "उपयोगकर्ता अवरुद्ध! एक मिनट के बाद प्रयास करें",
            "signInOtpContact.err429_plural": "उपयोगकर्ता अवरुद्ध! {{count}} मिनट के बाद प्रयास करें",
            "signInOtpCode.h-1": "साइन इन करें",
            "signInOtpCode.f-otp-label": "OTP दर्ज करें *",
            "signInOtpCode.lk-password": "पासवर्ड के साथ साइन इन करें",
            "signInOtpCode.btn-continue": "जारी रखें",
            "signInOtpCode.btn-resend": "ओटीपी पुनः भेजें",
            "signInOtpCode.i-resend": "में ओटीपी पुनः भेजें",
            "signInOtpCode.succ-0": "OTP नाराजगी!",
            "signInOtpCode.err-0": "कुछ गलत हो गया!",
            "signInOtpCode.err-1": "गलत ओटीपी",
            "signInOtpCode.err-2": "दो कई असफल प्रयास!",
            "signInOtpCode.err429": "उपयोगकर्ता अवरुद्ध! एक मिनट के बाद प्रयास करें",
            "signInOtpCode.err429_plural": "उपयोगकर्ता अवरुद्ध! {{count}} मिनट के बाद प्रयास करें",
            "box.header-back_btn": "वापस जाओ"
        },
        "es": {
            "app.i-help": "¿Necesitas ayuda? Contacto Soporte Veris en helpdesk@veris.in",
            "app.err-required": "Este campo es requerido.",
            "app.err-contact-0": "Introduzca válida de correo electrónico o número de teléfono con código de país",
            "app.err-email-0": "Ingrese un email valido",
            "app.err-setPasssword-0": "La contraseña debe tener al menos un carácter en minúsculas",
            "app.err-setPasssword-1": "La contraseña debe tener al menos un carácter en mayúsculas",
            "app.err-setPasssword-2": "La contraseña debe tener al menos un número de",
            "app.err-setPasssword-3": "La contraseña debe tener al menos un carácter especial, caracteres permitidos! PS",
            "app.err-setPasssword-4": "caracteres especiales permitidos! PS",
            "app.err-setPasssword-5": "La contraseña debe tener al menos 10 caracteres",
            "app.err-confirmPasssword-0": "Las contraseñas no coinciden.",
            "app.err-otp-0": "Introduzca OTP válida",
            "domain.h-1": "organización de dominio",
            "domain.sh-1": "Introduzca su dominio de la organización Veris para empezar",
            "domain.f-domain-label": "organización de dominio",
            "domain.f-domain-placeholder": "Introduzca dominio",
            "domain.f-domain-suffix": ".veris.in",
            "domain.lk-forgot-domain": "Se olvidó de dominio?",
            "domain.btn-continue": "Seguir",
            "domain.err-0": "¡Dominio no encontrado!",
            "forgotDomain.h-1": "se olvidó de dominio",
            "forgotDomain.sh-1": "Por favor, introduzca su correo electrónico para recibir su lista de dominios",
            "forgotDomain.f-email-label": "Administrador E-Mail ID",
            "forgotDomain.f-email-placeholder": "Introduzca admin Identificación del correo electrónico",
            "forgotDomain.btn-send": "Enviar al correo electrónico",
            "forgotDomain.err-0": "¡Algo salió mal!",
            "signInPassword.h-1": "Registrarse en",
            "signInPassword.f-contact-label": "Su correo electrónico / teléfono *",
            "signInPassword.f-contact-placeholder": "Ingrese su e-mail o teléfono",
            "signInPassword.lk-otp": "Entra con OTP?",
            "signInPassword.f-password-label": "Tu contraseña",
            "signInPassword.f-password-placeholder": "Introducir la contraseña",
            "signInPassword.btn-continue": "Seguir",
            "signInPassword.lk-forgot": "¿Se te olvidó tu contraseña?",
            "signInPassword.btn-okta": "Ingresar con Okta",
            "signInPassword.btn-google": "Ingresar con Google",
            "signInPassword.err-0": "¡Credenciales incorrectas!",
            "signInPassword.err429": "¡Usuario bloqueado! tratar después de un minuto",
            "signInPassword.err429_plural": "¡Usuario bloqueado! tratar después de {{count}} minutos",
            "forgotPassword.h-1": "Se te olvidó tu contraseña",
            "forgotPassword.f-contact-label": "Su correo electrónico / teléfono *",
            "forgotPassword.f-contact-placeholder": "Ingrese su e-mail o teléfono",
            "forgotPassword.lk-otp": "Entra con OTP?",
            "forgotPassword.btn-verification-link": "Comprobación de solicitudes Enlace",
            "forgotPassword.i-footer": "Recibirá enlace de verificación en su contacto proporcionada.",
            "forgotPassword.err-0": "¡Algo salió mal!",
            "signInOtpContact.h-1": "Registrarse en",
            "signInOtpContact.f-contact-label": "Su correo electrónico / teléfono *",
            "signInOtpContact.f-contact-placeholder": "Ingrese su e-mail o teléfono",
            "signInOtpContact.lk-password": "Iniciar sesión con la contraseña?",
            "signInOtpContact.btn-continue": "Seguir",
            "signInOtpContact.i-footer": "Le enviaremos una OTP en su contacto proporcionada.",
            "signInOtpContact.err-0": "Miembro no existe!",
            "signInOtpContact.err429": "¡Usuario bloqueado! tratar después de un minuto",
            "signInOtpContact.err429_plural": "¡Usuario bloqueado! tratar después de {{count}} minutos",
            "signInOtpCode.h-1": "Registrarse en",
            "signInOtpCode.f-otp-label": "Introduzca OTP *",
            "signInOtpCode.lk-password": "Iniciar sesión con la contraseña?",
            "signInOtpCode.btn-continue": "Seguir",
            "signInOtpCode.btn-resend": "Reenviar mensaje de OTP",
            "signInOtpCode.i-resend": "Enviar de nuevo en OTP",
            "signInOtpCode.succ-0": "resienten OTP!",
            "signInOtpCode.err-0": "¡Algo salió mal!",
            "signInOtpCode.err-1": "OTP incorrecto",
            "signInOtpCode.err-2": "Dos de muchos intentos fallidos!",
            "signInOtpCode.err429": "¡Usuario bloqueado! tratar después de un minuto",
            "signInOtpCode.err429_plural": "¡Usuario bloqueado! tratar después de {{count}} minutos",
            "box.header-back_btn": "Regresa",
            "Languages": "Idiomas",
            "enterEmail": "Tu correo electrónico",
            "enterEmail-placeholder": "Por favor introduzca su correo electrónico"
        },
        "zh-CN": {
            "app.i-help": "需要帮忙？联系弗瑞斯支持在helpdesk@veris.in",
            "app.err-required": "这是必填栏。",
            "app.err-contact-0": "输入与国家代码有效的电子邮件或电话号码",
            "app.err-email-0": "输入有效邮件地址",
            "app.err-setPasssword-0": "密码应至少有一个小写字符",
            "app.err-setPasssword-1": "密码应至少有一个大写字符",
            "app.err-setPasssword-2": "密码应至少有一个数字",
            "app.err-setPasssword-3": "密码应至少有一个特殊字符，允许的字符！ @＃$＆*",
            "app.err-setPasssword-4": "允许的特殊字符！ @＃$＆*",
            "app.err-setPasssword-5": "密码应至少为10个字符",
            "app.err-confirmPasssword-0": "密码不匹配。",
            "app.err-otp-0": "输入有效的OTP",
            "domain.h-1": "组织域",
            "domain.sh-1": "输入您的弗瑞斯组织域启动",
            "domain.f-domain-label": "组织域",
            "domain.f-domain-placeholder": "输入域名",
            "domain.f-domain-suffix": ".veris.in",
            "domain.lk-forgot-domain": "忘记了域名？",
            "domain.btn-continue": "继续",
            "domain.err-0": "域名不存在！",
            "forgotDomain.h-1": "忘记域名",
            "forgotDomain.sh-1": "请输入您的电子邮件接收域列表",
            "forgotDomain.f-email-label": "联系电子邮件ID",
            "forgotDomain.f-email-placeholder": "输入admin电子邮件ID",
            "forgotDomain.btn-send": "发送电子邮件",
            "forgotDomain.err-0": "出了些问题！",
            "signInPassword.h-1": "登录到",
            "signInPassword.f-contact-label": "您的电子邮件/电话*",
            "signInPassword.f-contact-placeholder": "输入您的电子邮件或电话",
            "signInPassword.lk-otp": "拍在与OTP？",
            "signInPassword.f-password-label": "你的密码",
            "signInPassword.f-password-placeholder": "输入密码",
            "signInPassword.btn-continue": "继续",
            "signInPassword.lk-forgot": "忘记密码？",
            "signInPassword.btn-okta": "与1563登录",
            "signInPassword.btn-google": "与谷歌登录",
            "signInPassword.err-0": "不正确的凭据！",
            "signInPassword.err429": "用户堵住了！尝试一分钟后，",
            "signInPassword.err429_plural": "用户堵住了！尝试{{数}}分钟后",
            "forgotPassword.h-1": "忘记密码",
            "forgotPassword.f-contact-label": "您的电子邮件/电话*",
            "forgotPassword.f-contact-placeholder": "输入您的电子邮件或电话",
            "forgotPassword.lk-otp": "拍在与OTP？",
            "forgotPassword.btn-verification-link": "请求验证链接",
            "forgotPassword.i-footer": "我们会向您发送您提供的联系验证链接。",
            "forgotPassword.err-0": "出了些问题！",
            "signInOtpContact.h-1": "登录到",
            "signInOtpContact.f-contact-label": "您的电子邮件/电话*",
            "signInOtpContact.f-contact-placeholder": "输入您的电子邮件或电话",
            "signInOtpContact.lk-password": "拍在与密码？",
            "signInOtpContact.btn-continue": "继续",
            "signInOtpContact.i-footer": "我们将寄给你对你提供的联系的OTP。",
            "signInOtpContact.err-0": "会员不存在！",
            "signInOtpContact.err429": "用户堵住了！尝试一分钟后，",
            "signInOtpContact.err429_plural": "用户堵住了！尝试{{数}}分钟后",
            "signInOtpCode.h-1": "登录到",
            "signInOtpCode.f-otp-label": "输入OTP *",
            "signInOtpCode.lk-password": "拍在与密码？",
            "signInOtpCode.btn-continue": "继续",
            "signInOtpCode.btn-resend": "重发OTP",
            "signInOtpCode.i-resend": "重发在OTP",
            "signInOtpCode.succ-0": "OTP重发！",
            "signInOtpCode.err-0": "出了些问题！",
            "signInOtpCode.err-1": "错误的OTP",
            "signInOtpCode.err-2": "二许多失败的尝试！",
            "signInOtpCode.err429": "用户堵住了！尝试一分钟后，",
            "signInOtpCode.err429_plural": "用户堵住了！尝试{{数}}分钟后",
            "box.header-back_btn": "回去",
            "Languages": "语言",
            "enterEmail": "你的邮件",
            "enterEmail-placeholder": "请输入您的电子邮件"
        },
        "ja": {
            "app.i-help": "助けが必要？ helpdesk@veris.inでの問い合わせVerisのサポート",
            "app.err-required": "この項目は必須です。",
            "app.err-contact-0": "国コードと有効な電子メールまたは電話番号を入力します。",
            "app.err-email-0": "有効な電子メールを入力してください",
            "app.err-setPasssword-0": "パスワードは、少なくとも一つの小文字を持っている必要があります",
            "app.err-setPasssword-1": "パスワードは、少なくとも1つの大文字を持つべきです",
            "app.err-setPasssword-2": "パスワードは、少なくとも一つの番号を持っている必要があります",
            "app.err-setPasssword-3": "パスワードは、少なくとも一つの特殊文字、使用できる文字を持っている必要があります！ @＃$＆*",
            "app.err-setPasssword-4": "可の特殊文字！ @＃$＆*",
            "app.err-setPasssword-5": "パスワードは少なくとも10文字にする必要があります",
            "app.err-confirmPasssword-0": "パスワードが一致しません。",
            "app.err-otp-0": "有効なOTPを入力します。",
            "domain.h-1": "組織ドメイン",
            "domain.sh-1": "開始するには、あなたのVerisの組織のドメインを入力します。",
            "domain.f-domain-label": "組織ドメイン",
            "domain.f-domain-placeholder": "ドメインを入力します。",
            "domain.f-domain-suffix": ".veris.in",
            "domain.lk-forgot-domain": "ドメインをお忘れですか？",
            "domain.btn-continue": "継続する",
            "domain.err-0": "ドメインが見つかりません！",
            "forgotDomain.h-1": "忘れたドメイン",
            "forgotDomain.sh-1": "ドメインのリストを受け取るためにあなたのメールアドレスを入力してください",
            "forgotDomain.f-email-label": "管理者の電子メールID",
            "forgotDomain.f-email-placeholder": "管理者の電子メールIDを入力します。",
            "forgotDomain.btn-send": "Eメールで送ります",
            "forgotDomain.err-0": "何かが間違っていました！",
            "signInPassword.h-1": "にサインイン",
            "signInPassword.f-contact-label": "あなたのメールアドレス/電話*",
            "signInPassword.f-contact-placeholder": "あなたの電子メールや電話を入力します。",
            "signInPassword.lk-otp": "OTPでサイン？",
            "signInPassword.f-password-label": "あなたのパスワード",
            "signInPassword.f-password-placeholder": "パスワードを入力する",
            "signInPassword.btn-continue": "継続する",
            "signInPassword.lk-forgot": "パスワードをお忘れですか？",
            "signInPassword.btn-okta": "Oktaでログイン",
            "signInPassword.btn-google": "Googleとログイン",
            "signInPassword.err-0": "誤った資格情報！",
            "signInPassword.err429": "ユーザーは、ブロックされました！分後にしてみてください",
            "signInPassword.err429_plural": "ユーザーは、ブロックされました！ {{回数}}分後にしてみてください",
            "forgotPassword.h-1": "パスワードをお忘れですか",
            "forgotPassword.f-contact-label": "あなたのメールアドレス/電話*",
            "forgotPassword.f-contact-placeholder": "あなたの電子メールや電話を入力します。",
            "forgotPassword.lk-otp": "OTPでサイン？",
            "forgotPassword.btn-verification-link": "確認リンクをリクエスト",
            "forgotPassword.i-footer": "私たちはあなた設けられた接触にあなたに確認リンクをお送りします。",
            "forgotPassword.err-0": "何かが間違っていました！",
            "signInOtpContact.h-1": "にサインイン",
            "signInOtpContact.f-contact-label": "あなたのメールアドレス/電話*",
            "signInOtpContact.f-contact-placeholder": "あなたの電子メールや電話を入力します。",
            "signInOtpContact.lk-password": "パスワードでサインイン？",
            "signInOtpContact.btn-continue": "継続する",
            "signInOtpContact.i-footer": "私たちはあなたの設けられたコンタクトにOTPを送信します。",
            "signInOtpContact.err-0": "メンバーは存在しません！",
            "signInOtpContact.err429": "ユーザーは、ブロックされました！分後にしてみてください",
            "signInOtpContact.err429_plural": "ユーザーは、ブロックされました！ {{回数}}分後にしてみてください",
            "signInOtpCode.h-1": "にサインイン",
            "signInOtpCode.f-otp-label": "OTPを入力してください*",
            "signInOtpCode.lk-password": "パスワードでサインイン？",
            "signInOtpCode.btn-continue": "継続する",
            "signInOtpCode.btn-resend": "再送OTP",
            "signInOtpCode.i-resend": "再送OTPで",
            "signInOtpCode.succ-0": "OTP再送信！",
            "signInOtpCode.err-0": "何かが間違っていました！",
            "signInOtpCode.err-1": "間違ったOTP",
            "signInOtpCode.err-2": "二つの多くの試みが失敗しました！",
            "signInOtpCode.err429": "ユーザーは、ブロックされました！分後にしてみてください",
            "signInOtpCode.err429_plural": "ユーザーは、ブロックされました！ {{回数}}分後にしてみてください",
            "box.header-back_btn": "戻る",
            "Languages": "言語",
            "enterEmail": "あなたのメール",
            "enterEmail-placeholder": "あなたのメールアドレスを入力してください"
        },
        "nl": {
            "app.i-help": "Hulp nodig? Contact Veris Support op helpdesk@veris.in",
            "app.err-required": "Dit veld is verplicht.",
            "app.err-contact-0": "Voer een geldige e-mail of telefoonnummer met landcode",
            "app.err-email-0": "Vul een geldig emailadres in",
            "app.err-setPasssword-0": "Wachtwoord moet ten minste één kleine letter hebben",
            "app.err-setPasssword-1": "Wachtwoord moet ten minste één hoofdletter hebben",
            "app.err-setPasssword-2": "Wachtwoord moet minstens één nummer hebben",
            "app.err-setPasssword-3": "Wachtwoord moet ten minste één speciaal teken, toegestaan ​​karakters te hebben! @ # $ & *",
            "app.err-setPasssword-4": "Toegestaan ​​speciale tekens! @ # $ & *",
            "app.err-setPasssword-5": "Wachtwoord moet ten minste 10 tekens",
            "app.err-confirmPasssword-0": "Wachtwoorden komen niet overeen.",
            "app.err-otp-0": "Voer een geldige otp",
            "domain.h-1": "organisatie Domain",
            "domain.sh-1": "Vul uw Veris organisatie domein te beginnen",
            "domain.f-domain-label": "organisatie Domain",
            "domain.f-domain-placeholder": "Enter Domain",
            "domain.f-domain-suffix": ".veris.in",
            "domain.lk-forgot-domain": "Wachtwoord Domain?",
            "domain.btn-continue": "Doorgaan met",
            "domain.err-0": "Domein niet gevonden!",
            "forgotDomain.h-1": "vergeten Domain",
            "forgotDomain.sh-1": "Vul uw e-mail aan uw lijst met domeinen te ontvangen",
            "forgotDomain.f-email-label": "Admin E-Mail ID",
            "forgotDomain.f-email-placeholder": "Voer admin e-mail id",
            "forgotDomain.btn-send": "Stuur on E-mail",
            "forgotDomain.err-0": "Er is iets fout gegaan!",
            "signInPassword.h-1": "Inloggen",
            "signInPassword.f-contact-label": "Uw e-mail / telefoon *",
            "signInPassword.f-contact-placeholder": "Vul uw e-mail of telefoon",
            "signInPassword.lk-otp": "Meld je aan met OTP?",
            "signInPassword.f-password-label": "Uw wachtwoord",
            "signInPassword.f-password-placeholder": "Voer wachtwoord in",
            "signInPassword.btn-continue": "Doorgaan met",
            "signInPassword.lk-forgot": "Wachtwoord vergeten?",
            "signInPassword.btn-okta": "Login met Okta",
            "signInPassword.btn-google": "Login met Google",
            "signInPassword.err-0": "Onjuiste referenties!",
            "signInPassword.err429": "Gebruiker geblokkeerd! probeer dan na een minuut",
            "signInPassword.err429_plural": "Gebruiker geblokkeerd! proberen na het {{count}} minuten",
            "forgotPassword.h-1": "Wachtwoord vergeten",
            "forgotPassword.f-contact-label": "Uw e-mail / telefoon *",
            "forgotPassword.f-contact-placeholder": "Vul uw e-mail of telefoon",
            "forgotPassword.lk-otp": "Meld je aan met otp?",
            "forgotPassword.btn-verification-link": "Vraag Verificatie Link",
            "forgotPassword.i-footer": "Wij zullen u de verificatie link en stuur hem op je voorzien contact.",
            "forgotPassword.err-0": "Er is iets fout gegaan!",
            "signInOtpContact.h-1": "Inloggen",
            "signInOtpContact.f-contact-label": "Uw e-mail / telefoon *",
            "signInOtpContact.f-contact-placeholder": "Vul uw e-mail of telefoon",
            "signInOtpContact.lk-password": "Log in met een wachtwoord?",
            "signInOtpContact.btn-continue": "Doorgaan met",
            "signInOtpContact.i-footer": "Wij sturen je een OTP sturen op uw voorzien contact.",
            "signInOtpContact.err-0": "Lid bestaat niet!",
            "signInOtpContact.err429": "Gebruiker geblokkeerd! probeer dan na een minuut",
            "signInOtpContact.err429_plural": "Gebruiker geblokkeerd! proberen na het {{count}} minuten",
            "signInOtpCode.h-1": "Inloggen",
            "signInOtpCode.f-otp-label": "Voer OTP *",
            "signInOtpCode.lk-password": "Log in met een wachtwoord?",
            "signInOtpCode.btn-continue": "Doorgaan met",
            "signInOtpCode.btn-resend": "Opnieuw verzenden OTP",
            "signInOtpCode.i-resend": "Opnieuw verzenden OTP in",
            "signInOtpCode.succ-0": "OTP kwalijk!",
            "signInOtpCode.err-0": "Er is iets fout gegaan!",
            "signInOtpCode.err-1": "verkeerde OTP",
            "signInOtpCode.err-2": "Twee vele mislukte pogingen!",
            "signInOtpCode.err429": "Gebruiker geblokkeerd! probeer dan na een minuut",
            "signInOtpCode.err429_plural": "Gebruiker geblokkeerd! proberen na het {{count}} minuten",
            "box.header-back_btn": "Ga terug",
            "Languages": "Talen",
            "enterEmail": "Jouw email",
            "enterEmail-placeholder": 'Vul alstublieft uw e-mailadres in'
        },
        "ro": {
            "app.i-help": "Nevoie de ajutor? Contactați Veris Suport la helpdesk@veris.in",
            "app.err-required": "Acest câmp este obligatoriu.",
            "app.err-contact-0": "Introduceți e-mail validă sau un număr de telefon cu codul de țară",
            "app.err-email-0": "Introdu e-mail validă",
            "app.err-setPasssword-0": "Parola ar trebui să aibă cel puțin un caracter mai",
            "app.err-setPasssword-1": "Parola ar trebui să aibă cel puțin un caracter caz superior",
            "app.err-setPasssword-2": "Parola ar trebui să aibă cel puțin un număr",
            "app.err-setPasssword-3": "Parola ar trebui să aibă cel puțin un caracter special, caracterele permise! @ # $ & *",
            "app.err-setPasssword-4": "Permise caractere speciale! @ # $ & *",
            "app.err-setPasssword-5": "Parola ar trebui să fie de cel puțin 10 de caractere",
            "app.err-confirmPasssword-0": "Parolele nu se potrivesc.",
            "app.err-otp-0": "Introduceți otp validă",
            "domain.h-1": "Organizarea de domeniu",
            "domain.sh-1": "Introduceți domeniul dvs. organizație Veris pentru a începe",
            "domain.f-domain-label": "Organizarea de domeniu",
            "domain.f-domain-placeholder": "Intră domeniu",
            "domain.f-domain-suffix": ".veris.in",
            "domain.lk-forgot-domain": "Ai uitat domeniu?",
            "domain.btn-continue": "Continua",
            "domain.err-0": "Domeniul nu a fost găsit!",
            "forgotDomain.h-1": "Ai uitat domeniu",
            "forgotDomain.sh-1": "Vă rugăm să introduceți adresa dvs. de email pentru a primi lista de domenii",
            "forgotDomain.f-email-label": "Admin E-mail ID",
            "forgotDomain.f-email-placeholder": "Introdu admin de e-mail id",
            "forgotDomain.btn-send": "Trimite pe E-mail",
            "forgotDomain.err-0": "Ceva n-a mers bine!",
            "signInPassword.h-1": "Înregistrează-te la",
            "signInPassword.f-contact-label": "Adresa dvs. de email / telefon *",
            "signInPassword.f-contact-placeholder": "Introdu e-mail sau telefon",
            "signInPassword.lk-otp": "Conectați-vă cu OTP?",
            "signInPassword.f-password-label": "Parola ta",
            "signInPassword.f-password-placeholder": "Introdu parola",
            "signInPassword.btn-continue": "Continua",
            "signInPassword.lk-forgot": "Ați uitat parola?",
            "signInPassword.btn-okta": "Autentifică-te cu Okta",
            "signInPassword.btn-google": "Autentifică-te cu Google",
            "signInPassword.err-0": "Date de conectare incorecte!",
            "signInPassword.err429": "Utilizator blocat! încercați după un minut",
            "signInPassword.err429_plural": "Utilizator blocat! încercați după {{count}} minute",
            "forgotPassword.h-1": "Ați uitat parola",
            "forgotPassword.f-contact-label": "Adresa dvs. de email / telefon *",
            "forgotPassword.f-contact-placeholder": "Introdu e-mail sau telefon",
            "forgotPassword.lk-otp": "Conectați-vă cu otp?",
            "forgotPassword.btn-verification-link": "Cerere de verificare Link",
            "forgotPassword.i-footer": "Vă vom trimite link-ul de verificare pe dvs. de contact furnizate.",
            "forgotPassword.err-0": "Ceva n-a mers bine!",
            "signInOtpContact.h-1": "Înregistrează-te la",
            "signInOtpContact.f-contact-label": "Adresa dvs. de email / telefon *",
            "signInOtpContact.f-contact-placeholder": "Introdu e-mail sau telefon",
            "signInOtpContact.lk-password": "Conectați-vă cu parola?",
            "signInOtpContact.btn-continue": "Continua",
            "signInOtpContact.i-footer": "Vă vom trimite un OTP pe dvs. de contact furnizate.",
            "signInOtpContact.err-0": "Membru nu există!",
            "signInOtpContact.err429": "Utilizator blocat! încercați după un minut",
            "signInOtpContact.err429_plural": "Utilizator blocat! încercați după {{count}} minute",
            "signInOtpCode.h-1": "Înregistrează-te la",
            "signInOtpCode.f-otp-label": "Introduceți OTP *",
            "signInOtpCode.lk-password": "Conectați-vă cu parola?",
            "signInOtpCode.btn-continue": "Continua",
            "signInOtpCode.btn-resend": "Retrimiteți OTP",
            "signInOtpCode.i-resend": "Retrimiteți OTP în",
            "signInOtpCode.succ-0": "retrimiterea OTP!",
            "signInOtpCode.err-0": "Ceva n-a mers bine!",
            "signInOtpCode.err-1": "OTP greșită",
            "signInOtpCode.err-2": "Două multe încercări nereușite!",
            "signInOtpCode.err429": "Utilizator blocat! încercați după un minut",
            "signInOtpCode.err429_plural": "Utilizator blocat! încercați după {{count}} minute",
            "box.header-back_btn": "Întoarce-te",
            "Languages": "Limbile",
            "enterEmail": "Email-ul tau",
            "enterEmail-placeholder": 'Te rog introdu e-mailul tău'
        }
    }
