// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "/* purgecss start ignore */\n\n.login-Container-fXs3i{\n  width: 360px;\n  background-color: white;\n  border-radius: 9px;\n  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);\n  margin-top: 36px;\n}\n\n@media only screen and (max-width: 576px) {\n  .login-Container-fXs3i{\n    width: 80%;\n    margin: auto;\n  }\n}\n\n/* purgecss end ignore */", "",{"version":3,"sources":["<no source>","styles.module.css"],"names":[],"mappings":"AAAA,2BAAA;;ACAA;EACI,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,8CAA8C;EAC9C,gBAAgB;AACpB;;AAEA;EACI;IACI,UAAU;IACV,YAAY;EAChB;AACJ;;ADbA,yBAAA","file":"styles.module.css","sourcesContent":[null,".Container{\r\n    width: 360px;\r\n    background-color: white;\r\n    border-radius: 9px;\r\n    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);\r\n    margin-top: 36px;\r\n}\r\n\r\n@media only screen and (max-width: 576px) {\r\n    .Container{\r\n        width: 80%;\r\n        margin: auto;\r\n    }\r\n}"]}]);
// Exports
exports.locals = {
	"Container": "login-Container-fXs3i"
};
module.exports = exports;
