// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "/* purgecss start ignore */\n\n.login-Policy-3eYtU h3{\n  padding: 0px;\n  margin: 0px;\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.login-Policy-3eYtU ul{\n  margin-bottom: 24px;\n}\n\n.login-Policy-3eYtU ul li{\n  padding: 0px;\n  margin: 0px;\n  font-size: 12px;\n  line-height: 20px;\n}\n\n/* purgecss end ignore */", "",{"version":3,"sources":["<no source>","styles.module.css"],"names":[],"mappings":"AAAA,2BAAA;;ACAA;EACI,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;AACrB;;AACA;EACI,mBAAmB;AACvB;;AACA;EACI,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;AACrB;;ADdA,yBAAA","file":"styles.module.css","sourcesContent":[null,".Policy h3{\r\n    padding: 0px;\r\n    margin: 0px;\r\n    font-size: 16px;\r\n    line-height: 24px;\r\n}\r\n.Policy ul{\r\n    margin-bottom: 24px;\r\n}\r\n.Policy ul li{\r\n    padding: 0px;\r\n    margin: 0px;\r\n    font-size: 12px;\r\n    line-height: 20px;\r\n}"]}]);
// Exports
exports.locals = {
	"Policy": "login-Policy-3eYtU"
};
module.exports = exports;
