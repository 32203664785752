// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "/* purgecss start ignore */\n\n.login-Container-3LgUP{\n  margin-bottom: 24px;\n  padding: 0px;\n  font-size: 10px;\n  line-height: 14px;\n  text-align: center;\n  color: #6e7380;\n}\n\n/* purgecss end ignore */\r\n", "",{"version":3,"sources":["<no source>","styles.module.css"],"names":[],"mappings":"AAAA,2BAAA;;ACAA;EACI,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAClB;;ADPA,yBAAA","file":"styles.module.css","sourcesContent":[null,".Container{\r\n    margin-bottom: 24px;\r\n    padding: 0px;\r\n    font-size: 10px;\r\n    line-height: 14px;\r\n    text-align: center;\r\n    color: #6e7380;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"Container": "login-Container-3LgUP"
};
module.exports = exports;
